<template>
  <div class="text-gray-800 border-t-4 border-blue-500 modal">
    <div class="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        User Profile
      </h3>
    </div>

    <div class="px-8 py-4 modal-content bg-neutral-gray">
      <div class="p-4 my-4 border border-gray-200 rounded-lg bg-gray-50">
        <ul class="flex mx-4 text-sm font-bold">
          <li
            v-for="t in tabs"
            :key="t.key"
            class="mr-3 md:mr-4 lg:mr-6"
          >
            <a
              class="block pb-2 text-gray-600 border-b-2 border-transparent hover:text-gray-700 hover:border-gray-700"
              :class="{ 'border-blue-500 text-blue-500' : tab === t.key }"
              href="#"
              @click.prevent="tab = t.key"
            >
              {{ t.label }}
            </a>
          </li>
        </ul>

        <div class="pt-8">
          <component
            :is="activeTab"
            @close="$emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const ProfileOverview = () => import('@components/profile/ProfileOverview')
const ProfileSecurity = () => import('@components/profile/ProfileSecurity')
const ProfileAccess = () => import('@components/profile/ProfileAccess')
const ProfileApi = () => import('@components/profile/ProfileApi')
const ProfileFlags = () => import('@components/profile/ProfileFlags')

export default {
  name: 'UserProfileModal',

  components: {
    ProfileOverview,
    ProfileSecurity,
    ProfileAccess,
    ProfileFlags,
    ProfileApi
  },

  data () {
    return {
      tab: 'overview',

      tabs: [
        { key: 'overview', label: 'Profile' },
        { key: 'security', label: 'Security' },
        { key: 'access', label: 'Teams & Access' },
        { key: 'api', label: 'API' }
      ]
    }
  },

  computed: {

    isEnvProduction () {
      return (this.appMode === 'production')
    },

    activeTab () {
      switch (this.tab) {
        case 'security':
          return 'ProfileSecurity'
        case 'access':
          return 'ProfileAccess'
        case 'flags':
          return 'ProfileFlags'
        case 'api':
          return 'ProfileApi'
        default:
          return 'ProfileOverview'
      }
    },

    user () {
      return this.$store.getters.user
    }

  },

  mounted () {
    if (!this.isEnvProduction) {
      this.tabs.push({ key: 'flags', label: 'Flags' })
    }
  }
}
</script>
